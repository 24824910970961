@font-face {
    font-family: "RobotoBold";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("./fonts/Roboto-Bold.ttf") format("trueType");
}

@font-face {
    font-family: "RobotoMedium";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("./fonts/Roboto-Medium.ttf") format("trueType");
}

@font-face {
    font-family: "RobotoRegular";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("./fonts/Roboto-Regular.ttf") format("trueType");
}