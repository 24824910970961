.raspItem {
    background-color: var(--green);
    border-radius: 4px;
    padding: 10px;
    margin-top: 10px;
}

@media (min-width: 1224px), print {
    .raspItem {
        background-color: unset;
        border-radius: unset;
        padding: unset;
        margin-top: 20px;
    }
}