.SearchButtons {
    display: flex;
    flex-wrap: nowrap;
}

.SearchButtons a {
    display: grid;
    width: 50%;
    padding: 16px 20px;
    align-items: center;
    align-content: center;
    background-color: #429C97;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
}

.SearchButtons a + a {
    margin-left: var(--items--gap);
}

.SearchHelper {
    margin-top: 20px;
    margin-bottom: 10px;
}

.SearchHelper__list {
    margin-top: 10px;
    margin-bottom: 10px;
}

@media (min-width: 1224px), print {
    .SearchHelper {
        margin-top: 40px;
        margin-bottom: 20px;
    }

    .SearchHelper__list {
        margin-top: unset;
        margin-bottom: 20px;
    }
}

.SearchHide {
    display: none;
}